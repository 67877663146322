import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./newsslider.css";

const NewsSlider = () => {
  const newsData = [
    { title: "Top Strategies for Cracking IIT JEE: Insights from Bansal Classes Experts", link: "/tips" },
    { title: "Success Stories: Alumni of Bansal Classes Who Achieved Their Dreams", link: "/success-story" },
    { title: "The Test for JEE & NEET Division is To be Conducted Soon", link: "/test-results" },
    { title: "Register for India’s Biggest Scholarship Exam with Scholarship worth 3cr", link: "" },
    { title: "The Results for Test Conducted recently for Junior Division are out", link: "/test-results" }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [newsData.length]);

  const handleNewsClick = (link) => {
    if (link) navigate(link);
  };

  const goToNextNews = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
  };

  const goToPreviousNews = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + newsData.length) % newsData.length);
  };

  return (
    <div className="news-slider-container">
      <div className="latest-update">Latest Updates!</div>
      <div className="news-wrapper">
        <button className="nav-button left" onClick={goToPreviousNews}>&lt;</button>
        <div className="news-list" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {newsData.map((news, index) => (
            <div
              key={index}
              className="news-item"
              onClick={() => handleNewsClick(news.link)}
            >
              {news.title}
            </div>
          ))}
        </div>
        <button className="nav-button right" onClick={goToNextNews}>&gt;</button>
      </div>
    </div>
  );
};

export default NewsSlider;
