import React from 'react'
import './testresult.css'
const TestResult = () => {
  return (
    <>
        <h2>
        The Test for JEE & NEET Division is To be Conducted soon. 
        </h2>   

        <p>
        The Test for JEE & NEET Division is To be Conducted soon. The syllabus is uploaded in Telegram Group. For getting added in the Telegram Group, please call us at +916232042164 with your child’s Name and Roll no for Telegrams groups link.

        </p> 

        <h2>
        The Results for Test Conducted recently for JEE & NEET Division are out. 
        </h2>
        <p>
        The Results for Test Conducted recently are out. Ther results can be viewed in official Telegram Group, or Please call us at -916232102606 with students roll no for detailed result. For getting added in the Telegram Group, please call us at +916232042164 with your child’s Name and Roll no for Telegrams groups link.
        </p>

        <h2>
        The Results for Test Conducted recently for Junior Division are out. 
        </h2>
        <p>
        The Results for Test Conducted recently are out. Ther results can be viewed in official Telegram Group, or Please call us at -916232102606 with students roll no for detailed result. For getting added in the Telegram Group, please call us at +916232042164 with your child’s Name and Roll no for Telegrams groups link.
        </p>

        <h2>

        </h2>

    </>
  )
}

export default TestResult